import React from 'react';
import { bool, func } from 'prop-types';
import { StyledBurger } from '../styles/styledComponents/burger';

const Burger = ({ open, setOpen }) => {
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <div className="mobile-headline">Munder Trucking</div>
    </>
  );
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
