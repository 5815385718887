import React, { useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './styles/styledComponents/global';
import { theme } from './styles/styledComponents/theme';
import { StyledMobileHeader } from './styles/styledComponents/mobileHeaderWrapper';
import './styles/main.scss';

import Footer from './components/footer';
import Burger from './components/burger';
import Menu from './components/menu';
import Header from './components/header';
import About from './pages/about';
import Employees from './pages/employees';
import Employers from './pages/employers';
import Apply from './pages/apply';
import Contact from './pages/contact';
import NotFound from './pages/notFound';

import { useOnClickOutside } from './hooks';
import { CONTACT_EMAIL, CONTACT_PHONE } from './utils/constants';

function App() {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div className={`${open ? "app modal-open" : "app"}`}>
          <Header />

          <StyledMobileHeader ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </StyledMobileHeader>

          <div className="main-content-container">
            <Switch>
              <Route exact path="/">
                <About />
              </Route>

              <Route exact path="/employers">
                <Employers />
              </Route>

              <Route exact path="/employees">
                <Employees />
              </Route>

              <Route exact path="/employees/apply">
                <Apply
                  contactEmail={CONTACT_EMAIL}
                  contactPhone={CONTACT_PHONE}
                />
              </Route>

              <Route exact path="/contact">
                <Contact
                  contactEmail={CONTACT_EMAIL}
                  contactPhone={CONTACT_PHONE}
                />
              </Route>

              <Route>
                <NotFound
                  contactEmail={CONTACT_EMAIL}
                  contactPhone={CONTACT_PHONE}
                />
              </Route>
            </Switch>
          </div>
          <Footer contactEmail={CONTACT_EMAIL} contactPhone={CONTACT_PHONE} />
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
