import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from '../styles/styledComponents/menu';
import { NavLink } from "react-router-dom";

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open}>
      <NavLink exact to="/" className="header-item" activeClassName="selected" onClick={() => setOpen(!open)}>About</NavLink>
      <NavLink exact to="/employers" className="header-item" activeClassName="selected" onClick={() => setOpen(!open)}>Hire Us</NavLink>
      <NavLink exact to="/employees" className="header-item" activeClassName="selected" onClick={() => setOpen(!open)}>Employees</NavLink>
      <NavLink exact to="/employees/apply" className="header-item" activeClassName="selected" onClick={() => setOpen(!open)}>Apply Now</NavLink>
      <NavLink exact to="/contact" className="header-item" activeClassName="selected" onClick={() => setOpen(!open)}>Contact</NavLink>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
