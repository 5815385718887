import { createGlobalStyle } from 'styled-components';
import truckImage from '../../assets/background-image-truck-mountains.jpg';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.primaryLight};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Brandon, BrandonMedium, BrandonBold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  html {
    font-size: 16px; /* Base for rems set here */
    background: url(${truckImage}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  html,body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  h1 {
    font-family: BrandonMedium;
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  h2 {
    font-family: BrandonMedium;
    font-size: 2rem;
  }

  a {
    color: #f65335;
  }

  a:hover {
    color: #14a9e6;
  }

  /* Mobile style below */
  @media (max-width: ${({ theme }) => theme.mobile}) {
  }
`;
