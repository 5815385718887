import styled from 'styled-components';

export const StyledMobileHeader = styled.div`
  display: none;

  /* Mobile style below */
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: block;
    min-height: 6rem;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity/see-through */
    border-bottom: 2px solid white;
  }
`;
