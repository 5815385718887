import React from 'react';
import { CONTACT_PHONE, CONTACT_EMAIL} from '../utils/constants';

function About() {
  return (
    <div className="background-text">
      <div className="commitments-wrapper">
        <h1>Safety</h1>
        <span>&bull;</span>
        <h1>Dependability</h1>
        <span>&bull;</span>
        <h1>Quality</h1>
      </div>
      <p>
        Munder Trucking is a family owned business offering transportation
        services out of Eastern Washington since 2002. Using our fleet of vans,
        box trucks, flatbeds and reefers we offer transportation services for
        the Eastern Washington and North East Oregon territories. We pride
        ourselves on offering outstanding service, competitive pricing and
        employing a crew of hard working drivers to ensure your products are
        delivered safely and on time. We are fully insured and stand behind our
        commitment to deliver a consistently high standard of service to our
        clients.
      </p>
      <p>
        Whether you are a driver looking for your next career opportunity or a
        future client looking for a safe and timely option to deliver your
        goods, we look forward to hearing from you. You can contact us via phone
        at <a href={"tel:+1" + CONTACT_PHONE}>{CONTACT_PHONE}</a>, via email at{" "}
        <a href={"mailto:" + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>, or use the
        contact form <a href="/contact">HERE</a>.
      </p>
      <p>Thank you for visiting!</p>
    </div>
  );
}

export default About;
