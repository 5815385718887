import React from 'react';

function NotFound() {
  return (
    <div className="background-text not-found">
      <h1>Whoops, looks like this page doesn't exist!</h1>
      <small>
        If you're not sure why you got here, please send us a message describing
        what you are looking for <a href="/contact">HERE</a>.
      </small>
      <br></br>
      <small>
        To go back to the home page click <a href="/">HERE</a>
      </small>
    </div>
  );
}

export default NotFound;
