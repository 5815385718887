import React from 'react';

function Employers() {
  return (
    <div className="background-text employers">
      <h1>Hire Munder Trucking for your transportation needs today!</h1>
      <h2>Our fleet includes:</h2>
      <ul>
        <li>Flatbed trucks</li>
        <li>Sprinter vans</li>
        <li>Box trucks</li>
        <li>Semi trucks and reefer trailers</li>
      </ul>

      <h2>Our services:</h2>
      <ul>
        <li>Local delivery</li>
        <li>Long haul transportation in the intercontinental US</li>
        <li>Long and short term contracts</li>
        <li>Single-use contracts</li>
      </ul>

      <p>If you would like a quote, or to find out more, please contact us via phone or email.</p>
    </div>  );
}

export default Employers;
