import React from 'react';
import { CONTACT_FORM_NAME } from '../utils/constants';

function ContactForm(props) {
  return (
    <>
      <h1>We look forward to hearing from you!</h1>
      <form
        id="contact-form"
        onSubmit={props.handleSubmit}
        method="POST"
        name={CONTACT_FORM_NAME}
        action="/submit-success"
      >
        <input type="hidden" name="form-name" value={CONTACT_FORM_NAME} />

        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="contact-form-name"
            name="name"
            className="form-control name"
            aria-describedby="name"
            value={props.name}
            onChange={props.handleChange}
            maxLength="256"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address:</label>
          <input
            type="email"
            id="contact-form-email"
            name="email"
            className="form-control email"
            aria-describedby="email"
            value={props.email}
            onChange={props.handleChange}
            maxLength="256"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="contact-form-message"
            className="form-control message"
            name="message"
            rows="10"
            aria-describedby="message"
            value={props.message}
            onChange={props.handleChange}
            maxLength="5000"
            required
          />
        </div>

        <button type="submit" aria-describedby="submit" className="btn">
          Submit
        </button>
      </form>
    </>
  );
}

export default ContactForm;
