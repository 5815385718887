import React from 'react';

function Footer(props) {
  return (
    <footer className="footer">
      <div className="footer-item">{props.contactPhone}</div>
  <div className="footer-item">{props.contactEmail}</div>
    </footer>
  );
}

export default Footer;
