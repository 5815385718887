import React from 'react';
import { encode } from '../utils/encode';
import { CONTACT_FORM_NAME } from '../utils/constants';
import ContactForm from '../components/contactForm';
import SubmitSuccess from '../components/submitSuccess';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      submitSuccess: false,
    }
  }

  resetForm = () => {
    this.setState({ name: '', email: '', message: '' })
  }

  handleErrors = response => {
    if(!response.ok) {
      console.log(response.statusText);
      throw Error(response.statusText);
    }

    this.resetForm();

    this.setState({ submitSuccess: true });

    return response;
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": CONTACT_FORM_NAME, ...this.state })
    })
      .then(this.handleErrors)
      .catch(error => {
        alert('Something went wrong. Please email us directly.');
        console.log(error);
        this.setState({ submitSuccess: false })
      });

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    let { name, email, message, submitSuccess } = this.state;

    return (
      <div className={"background-text " + CONTACT_FORM_NAME}>
        {
          submitSuccess
            ? <SubmitSuccess />
            : <ContactForm
                name={name}
                email={email}
                message={message}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
        }
      </div>
    );
  }
}

export default Contact;
