import React from 'react';
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <header className="header">
      <NavLink exact to="/" className="header-item" activeClassName="selected">About</NavLink>
      <NavLink exact to="/employers" className="header-item" activeClassName="selected">Hire Us</NavLink>
      <NavLink exact to="/employees" className="header-item" activeClassName="selected">Employees</NavLink>
      <NavLink exact to="/employees/apply" className="header-item" activeClassName="selected">Apply Now</NavLink>
      <NavLink exact to="/contact" className="header-item" activeClassName="selected">Contact</NavLink>
    </header>
  );
}

export default Header;
