import React from 'react';

function Apply(props) {
  return (
    <>
      <div className="background-text">
        This page is currently under construction.
      </div>
      <p className="background-text">
        To apply, send your resume to{" "}
        <a href={"mailto:" + props.contactEmail}>{props.contactEmail}</a> or
        give us a call at {props.contactPhone}
      </p>
    </>
  );
}

export default Apply;
