import React from 'react';

function Employees() {
  return (
    <div className="background-text employees">
      <h1>Become part of the Munder Trucking family today!</h1>
      <small>
        * Please review the items below before submitting an application.
      </small>

      <h2>What we provide:</h2>
      <ul>
        <li>Competitive wages</li>
        <li>Generous Paid Time Off (PTO) policy</li>
        <li>Stable work environment where all employees are treated fairly</li>
        <li>
          Vehicles, uniforms and any accessories which will make your job easier
        </li>
      </ul>

      <h2>Our ideal driver:</h2>
      <ul>
        <li>Holds a valid CDL license</li>
        <li>Contains no negative marks on their driving record</li>
        <li>Can prove they are a legal US resident</li>
        <li>Is seeking a satisfying, long term career as a driver</li>
      </ul>

      <p>
        If you meet the above criteria, click{" "}
        <a href="/employees/apply">HERE</a> to begin your application.
      </p>
    </div>
  );
}

export default Employees;
